//import "../styles/styles.scss"
import { navigate } from 'gatsby-link'


const Homepage = () => {
  if (typeof window !== `undefined`) {
    navigate("/");
  }

  return null;
}

export default Homepage
